import {
  Box,
  Heading,
  ListItem,
  OrderedList,
  Spacer,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import Footer from "../components/layout/Footer";

const TermsAndConditionsPage = () => {
  return (
    <Box>
      <Box py="32">
        <Box maxW="4xl" mx="auto" boxShadow="base" py="8" px="16">
          <Heading fontWeight="thin" mb="8">
            Términos Generales de uso de la Aplicación Móvil "App PAGOFLASH"{" "}
          </Heading>
          <Text fontWeight="thin" fontSize="lg">
            El presente Acuerdo es un contrato entre usted y PAGOFLASH
            INTERNATIONAL C.A. (en lo sucesivo los “PagoFlash”), sociedad
            mercantil inscrita en el Registro Mercantil Segundo de la
            Circunscripción Judicial del Distrito Capital y Estado Miranda, en
            fecha diez (10) de junio del año dos mil trece (2013), bajo el N° 93
            Tomo 61-A-SDO, refundidos sus estatutos por ante el mismo Registro
            en fecha 20 de marzo de 2018, bajo el Nro. 6 Tomo 63-A Sdo, cuya
            última Acta de Asamblea General Extraordinaria de Accionistas fue
            debidamente registrada en fecha 31 de enero de 2020, bajo el Nro.
            39, Tomo 19-A sdo identificada con el Registro Único de Información
            Fiscal (RIF) J-40256916-5 y se aplica a la utilización de todos los
            de servicios de PagoFlash. La utilización de los servicios de
            PagoFlash implica que usted acepta todos los términos y condiciones
            contenidos en este Acuerdo. Usted debe leer detenidamente todos
            estos términos y condiciones antes de decidir usar los servicios de
            PagoFlash. Nos reservamos el derecho de modificar este Acuerdo en
            cualquier momento mediante la publicación de una versión revisada en
            nuestro sitio web. Dicha versión entrará en vigor en el momento de
            su publicación. Si la versión modificada incluye un cambio
            sustancial, se lo notificaremos con al menos quince (15) días de
            anticipación a dicho cambio sustancial mediante la publicación en la
            página. Estos Términos de Uso también pueden ser ubicados en el
            sitio web de PagoFlash International, C.A. Al hacer uso de los
            servicios de PagoFlash usted acepta estos Términos de Uso, las
            Políticas de Privacidad y las Comisiones. En caso de no encontrarse
            de acuerdo con estos Términos de Uso absténgase de utilizar los
            servicios de Pago Flash:
            <Spacer my="4" />
            <OrderedList className="nested-counter-list">
              <ListItem>
                Para realizar operaciones de acuerdo a lo establecido en la
                resolución N° 18-12-01 de fecha 17 de diciembre de 2018, la cual
                contiene las “Normas Generales sobre los Sistemas de Pago y
                Proveedores No Bancarios de Servicios de Pago que Operan en el
                País. Dado a la alianza de PagoFlash con el Banco Nacional de
                Crédito (BNC) en lo sucesivo el Banco, los Clientes de
                PagoFlash, a través de la APP PagoFlash, en lo sucesivo APP, o
                cualesquiera otras herramientas, aplicaciones móviles o
                plataformas desarrolladas o licenciadas por PagoFlash, puede
                iniciar procesos para movilizar su Cuenta Transaccional, a
                través de pago móvil enviado a otros bancos, recibir
                transferencia y pago móvil de otros bancos, todas las
                operaciones en Moneda Nacional y exclusivamente en Venezuela.
              </ListItem>
              <ListItem>
                PagoFlash actúa en todo momento por cuenta de sus usuarios en
                las transacciones u operaciones ordenadas por éstos. Las
                transacciones que PagoFlash procesadas a través del Banco, son
                ejecutadas siguiendo las instrucciones dadas por el Usuario a
                través de la plataforma.
              </ListItem>
              <ListItem>
                PagoFlash a través de su plataforma APP PagoFlash permite a los
                Usuarios realizar la Apertura de la Cuenta Transaccional
                “PagoFlash Wallet, en lo sucesivo Cuenta Transaccional,
                movilizar el dinero, y realizar las operaciones o transacciones
                que se identifican a continuación:
                <Stack spacing={4} paddingLeft={6} paddingTop={3}>
                  <Text>
                    <Text as="b">a) </Text>
                    Operaciones de Recarga o “Cash In” a través de
                    transferencias interbancarias, pago móvil.
                  </Text>
                  <Text>
                    <Text as="b">b) </Text>
                    Operaciones de Retiro o “Cash Out” a través de pago móvil a
                    otras cuentas, Transferencias de dinero electrónico entre
                    usuarios de PagoFlash
                  </Text>
                  <Text>
                    <Text as="b">c) </Text>
                    Visualizar sus transacciones (pagos y transferencias
                    realizadas a través de la plataforma.
                  </Text>
                  <Text>
                    <Text as="b">d) </Text>
                    Consultar los saldos disponibles.
                  </Text>
                  <Text>
                    <Text as="b">e) </Text>
                    Recibir comunicaciones, notificaciones o información sobre
                    PagoFlash, incluyendo alertas o notificaciones de
                    transacciones sospechosas
                  </Text>
                  <Text>
                    <Text as="b">f) </Text>
                    Hacer uso de todos los servicios adicionales ofrecidos por
                    PagoFlash. La recepción de los fondos “Cash In” en la Cuenta
                    Transaccional no es considerada como captación de depósitos
                    ni como inversión a los fines de la legislación bancaria y
                    financiera aplicable.
                  </Text>
                </Stack>
              </ListItem>
              <ListItem>
                Para la Creación, Registro y Mantenimiento de su Cuenta para
                poder utilizar la APP PagoFlash el Usuario debe:
                <OrderedList>
                  <ListItem>
                    Descargar la aplicación de la tienda Play Store, el Usuario
                    necesita, como requerimientos mínimos, (a) una conexión
                    activa a Internet
                  </ListItem>
                  <ListItem>
                    Registrarse en la plataforma creando un “usuario” y
                    asignando una contraseña, y siguiendo los pasos que le
                    indique la aplicación. Para activar su Cuenta usted deberá
                    aceptar estos Términos de Uso y nuestras Políticas de
                    Privacidad y cargar en la APP la imagen digitalizada de la
                    Cédula de Identidad y del Registro de Información Fiscal
                    (RIF).
                  </ListItem>
                  <ListItem>
                    . El usuario al registrarse, entiende que abre una Cuenta
                    Transaccional en PagoFlash, y podrá administrar los fondos
                    depositados en la cuenta.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                Si usted es una persona natural, durante el proceso de registro
                deberá suministrarnos información sobre usted que puede incluir,
                entre otros, su nombre, apellido, fecha de nacimiento, cédula de
                identidad, registro único de información fiscal (RIF), dirección
                de correo electrónico (e-mail), número de teléfono móvil
                (celular), profesión u oficio, actividad económica, origen de
                los fondos a ser depositados, dirección de habitación, y código
                postal. Para poder activar su Cuenta, la información que usted
                suministre debe permitir a PagoFlash, a su satisfacción,
                verificar su identidad, por lo que PagoFlash podrá solicitar al
                Usuario el envío de comprobantes, copias de documentos de
                identidad, y/o información adicional que permita a PagoFlash
                corroborar la información suministrada por un Usuario.
              </ListItem>
              <ListItem>
                Para poder activar su Cuenta Transaccional, la información que
                usted suministre será validada y verificada por PagoFlash. El
                Usuario es responsable de la veracidad, autenticidad, exactitud
                y plenitud de la información suministrada. El Usuario es
                responsable y se obliga a mantener actualizada la información
                suministrada a PagoFlash para la creación de su Cuenta y su
                registro como usuario de la APP PagoFlash. Con la aceptación de
                estos Términos de Uso, usted autoriza a PagoFlash, directamente
                o a través de terceros, a realizar averiguaciones o
                investigaciones necesarias o convenientes para validar su
                identidad y la información cargada por usted al momento del
                registro de su Cuenta. Estas gestiones pueden traer como
                consecuencia que usted deba suministrar información adicional a
                PagoFlash para poder crear y registrar su Cuenta, incluyendo
                información personal, legal, financiera o referencias bancarias
                del Usuario. PagoFlash se reserva el derecho a rechazar o negar
                la creación de una Cuenta o el registro de un usuario, suspender
                temporal o definitivamente el acceso a la Cuenta del Usuario o
                cancelar la Cuenta del Usuario cuando el Usuario haya
                suministrado información inexacta, falsa, incompleta o
                inconsistente, o cuando el Usuario no cumpla con los
                requerimientos para la creación de una Cuenta o el registro del
                Usuario. Para mantener activa su Cuenta, el Usuario debe
                mantener activo y operativo en todo momento un número de
                teléfono y la dirección de correo electrónico (e-mail) asociada
                a su Cuenta.
              </ListItem>
              <ListItem>
                Seguridad de su Cuenta, El Usuario es responsable de resguardar
                y proteger su información de usuario y su contraseña para
                acceder a su Cuenta y hacer uso de los servicios que ofrece la
                APP. El Usuario deberá adoptar todas las medidas razonables para
                restringir el acceso por parte de terceros no autorizados a su
                Cuenta. PagoFlash en ningún momento le solicitará que usted
                proporcione a PagoFlash o a algún tercero, información sobre su
                usuario o contraseña. El Usuario es responsable y responde
                frente a PagoFlash y frente a terceros por las operaciones o
                transacciones efectuadas en su Cuenta por terceros a quienes el
                Usuario haya otorgado acceso a su Cuenta. PagoFlash le
                recomienda cambiar frecuentemente su contraseña de acceso a su
                Cuenta para reducir el riesgo de accesos no autorizados.
                PagoFlash o la APP podrán requerir al Usuario que realice
                cambios periódicos a su contraseña de acceso a la Cuenta.
              </ListItem>
              <ListItem>
                El Usuario notificará inmediatamente a PagoFlash acerca de
                cualquier acceso o uso no autorizado a su Cuenta, a través del
                Centro de Atención al Cliente de PagoFlash. PagoFlash, podrá
                suspender su acceso a la Cuenta o limitar o restringir
                cualesquiera de los servicios de la App por causas relacionadas
                a la seguridad e integridad de su Cuenta, o si considera o
                sospecha que se ha producido un acceso no autorizado a su Cuenta
                o una transacción fraudulenta a través de la App. El Usuario es
                responsable de la seguridad y privacidad de su cuenta de correo
                electrónico (email). Su dirección de correo electrónico
                utilizada para crear su Cuenta y registrarse en la App podrá ser
                utilizada por PagoFlash en los procesos de recuperación o
                restablecimiento de contraseñas, para enviar comunicaciones al
                Usuarios sobre la seguridad de su Cuenta o, en general, para
                enviar comunicaciones sobre su Cuenta, la App
              </ListItem>
              <ListItem>
                Prohibiciones, Restricciones o Limitaciones al Uso de la Cuenta
                y de los Servicios de la App, El Usuario es responsable del uso
                que da a su Cuenta y de las operaciones y transacciones que
                realiza a través de la App. Usted se compromete a no hacer uso
                indebido de su Cuenta o de la App. El Usuario no deberá utilizar
                o servirse de la App (ni permitirá que terceros autorizados a
                acceder a su Cuenta utilicen o se sirvan de la App) para:
                <Stack spacing={4} paddingLeft={6} paddingTop={3}>
                  <Text>
                    <Text as="b">a) </Text>
                    Hacer uso de la App o de su Cuenta de manera que viole las
                    disposiciones de estos Términos de Uso, de la Política de
                    Privacidad o de cualquier otro contrato entre el Usuario y
                    PagoFlash
                  </Text>
                  <Text>
                    <Text as="b">b) </Text>
                    Pagar, recibir el pago, reembolsar, vender, comprar o de
                    cualquier otra forma enajenar, adquirir, comercializar,
                    custodiar o almacenar armas de fuego, municiones, repuestos
                    de armamento, material explosivo; sustancias o productos
                    ilegales o prohibidos, tales como estupefacientes, drogas o
                    alucinógenos prohibidos, así como artefactos o herramientas
                    que sirvan para producir, procesar, consumir o promover el
                    consumo, distribución y producción de las sustancias
                    prohibidas; productos que se encuentren fuera del comercio
                    cuya comercialización se encuentre prohibida por la Ley
                    aplicable; bienes o servicios engañosos, discriminatorios o
                    fraudulentos; artículos falsificados; artefactos o productos
                    pirotécnicos; bienes o servicios que infrinjan los derechos
                    de Propiedad Intelectual de PagoFlash o de cualquier
                    tercero; o material difamatorio, ofensivo o que promueva la
                    violencia, el odio o la discriminación
                  </Text>
                  <Text>
                    <Text as="b">c) </Text>
                    Pagar, recibir el pago, reembolsar, vender, comprar o de
                    cualquier otra forma enajenar, adquirir, custodiar,
                    almacenar o facilitar el comercio ilícito de recursos o
                    materiales estratégicos, metales o piedras preciosas, o
                    recursos o materiales nucleares o radiactivos y sus
                    productos o derivados
                  </Text>
                  <Text>
                    <Text as="b">d) </Text>
                    Cualquier fin ilegal, incluyendo cualquier actividad que
                    implique el incumplimiento de las regulaciones en material
                    de legitimación de capitales, financiamiento al terrorismo,
                    financiamiento de armas de destrucción masiva o delincuencia
                    organizada
                  </Text>
                  <Text>
                    <Text as="b">e) </Text>
                    Realizar o recibir pagos, o transferencias vinculados a
                    servicios de loterías, juego o apuestas ilegales
                  </Text>
                  <Text>
                    <Text as="b">f) </Text>
                    Captar inversiones o depósitos, o participar, ofrecer,
                    anunciar, implementar, administrar, invitar, captar recursos
                    o de cualquier otra forma influir a otros usuarios o a
                    terceros a participar en negocios o esquemas “Ponzi” o
                    piramidales, o programas u oportunidades de inversión; •
                    Abrir o mantener más de una Cuenta en la app
                  </Text>
                  <Text>
                    <Text as="b">g) </Text>
                    Permitir que su Cuenta tenga un saldo negativo
                  </Text>
                  <Text>
                    <Text as="b">h) </Text>
                    Distribuir, divulgar, anunciar o publicar información,
                    contenido o material con fines políticos, o que incite a la
                    violencia, el odio, la segregación o la discriminación, o
                    que de cualquier otra forma degrade, intimide o amenace a
                    cualquier otro usuario, terceros o a PagoFlash
                  </Text>
                  <Text>
                    <Text as="b">i) </Text>
                    Distribuir, divulgar, anunciar o publicar cualquier
                    información, contenido o material indecente, obsceno o
                    ilegal
                  </Text>
                  <Text>
                    <Text as="b">j) </Text>
                    Descargar o cargar archivos o programas maliciosos que
                    puedan dañar o perjudicar su Cuenta o la de otros usuarios,
                    la App, los equipos, servicios, data, información,
                    computadoras, archivos o programas de PagoFlash, de otros
                    usuarios de la App o de terceros; tales como virus, archivos
                    corruptos, troyanos, malware, gusanos o algún software
                    similar
                  </Text>
                  <Text>
                    <Text as="b">k) </Text>
                    Descargar o cargar archivos o programas que contengan
                    material que viole la Propiedad Intelectual o la privacidad
                    de PagoFlash o de terceros
                  </Text>
                  <Text>
                    <Text as="b">m) </Text>
                    Modificar, copiar, alterar, distribuir, transmitir, mostrar,
                    exhibir, reproducir, publicar, transferir o enajenar la
                    información de la App y cualquiera de sus componentes, o los
                    servicios ofrecidos por PagoFlash
                  </Text>
                  <Text>
                    <Text as="b">n) </Text>
                    Utilizar robots, arañas ni otros dispositivos automáticos o
                    procesos manuales para monitorear o copiar la App o su
                    contenido sin el consentimiento previo y por escrito de
                    PagoFlash
                  </Text>
                  <Text>
                    <Text as="b">o) </Text>
                    Obtener sin consentimiento previo y por escrito de
                    PagoFlash, distribuir o de cualquier otra forma dar a
                    conocer información confidencial, secretos comerciales,
                    procesos, programas informáticos, especificaciones técnicas
                    o información sobre los usuarios de la App o clientes de
                    PagoFlash
                  </Text>
                  <Text>
                    <Text as="b">p) </Text>
                    Interferir o interrumpir, o intentar interferir o
                    interrumpir los servicios de la App
                  </Text>
                  <Text paddingBottom={2}>
                    <Text as="b">q) </Text>
                    Ceder o de cualquier otra forma transferir su Cuenta o sus
                    derechos y obligaciones derivados de estos Términos de Uso
                  </Text>
                </Stack>
                Si PagoFlash considera que usted ha participado o promovido, o
                ha intentado participar en cualquiera de estas actividades, o
                que ha utilizado o se ha servido de la App o de su Cuenta para
                realizar cualquiera de las actividades prohibidas, restringidas
                o limitadas señaladas en estos Términos de Uso, en la Política
                de Privacidad o en cualquier otro acuerdo entre usted y
                PagoFlash, PagoFlash podrá, sin necesidad de notificar
                previamente al Usuario, (a) suspender, cerrar o limitar de
                manera inmediata las funcionalidades de su Cuenta o su acceso a
                los servicios de la App, sin que ello conlleve alguna
                penalización y obligación de indemnizar en cabeza de PagoFlash;
                (b) rescindir estos Términos de Uso; (c) rechazar o negarse a
                ejecutar cualquier operación o transacción ordenada por usted,
                incluyendo operaciones de Recarga, transferencia o pago; (d)
                rechazar o negarse en el futuro a abrir una Cuenta al Usuario o
                registrarlo para que use los servicios de la App. En general,
                PagoFlash se reserva el derecho a rechazar o negarse a ejecutar
                cualquier operación o transacción realizada por el Usuario a
                través de la App que, a criterio exclusivo de PagoFlash, sea
                contraria a la Ley aplicable o de cualquier forma trasgreda los
                derechos de PagoFlash, de otros usuarios o de terceros en
                general, incluyendo derechos de propiedad intelectual.
              </ListItem>
              <ListItem>
                Operaciones de “Cash In”, El Usuario puede en cualquier momento,
                efectuar operaciones de aumento de fondos a su Cuenta
                Transaccional. Para llevar a cabo operaciones de Cash In que
                permitan al Usuario incrementar o reponer el saldo para realizar
                operaciones o transacciones a través de la App, el Usuario puede
                realizar una transferencia o un pago móvil interbancario a la
                Cuenta individual que le será indicada en la App a fin de
                incrementar su saldo. El Usuario debe mantener un saldo
                suficiente en para cubrir el importe de las Comisiones por
                Recarga que corresponden a PagoFlash, en el momento de la
                transacción, PagoFlash acreditará en la Cuenta Transaccional del
                Usuario el monto recibido en transferencia o pago móvil, El
                Banco Aliado o PagoFlash pueden en cualquier momento establecer
                o modificar limitaciones a las operaciones de Cash In por
                razones de seguridad, para disminuir o controlar riesgos, o para
                dar cumplimiento a la normativa aplicable, inclusive mediante la
                fijación de montos mínimos o montos máximos por operación, por
                día, semana, mes o año. Los límites de Cash In podrán ser
                superiores o inferiores a los límites establecidos por PagoFlash
                para las operaciones de Retiro o las transacciones de
                transferencia o pagos.
              </ListItem>
              <ListItem>
                El Usuario que realice una operación de recarga reconoce que
                debe reportarla en la aplicación en un lapso que no excederá de
                treinta (30) días hábiles. En caso contrario y pasado el lapso
                antes mencionado el reclamo no procederá. Las operaciones de
                Recarga están sujetas al cobro, por parte del Banco y PagoFlash,
                de las comisiones que se identifican en la sección “Comisiones”
                en la página web www.pagoflash.com.
              </ListItem>
              <ListItem>
                Operaciones de Retiro o “Cash Out” El Usuario puede en cualquier
                momento durante la vigencia de este Contrato el retiro parcial o
                total de los fondos. Para hacer el retiro total de los fondos el
                Usuario debe cerrar su Cuenta siguiendo el procedimiento
                previsto en estos Términos de Uso.
              </ListItem>
              <ListItem>
                El Usuario no podrá hacer retiros por montos superiores al saldo
                disponible la Cuenta Transaccional. El Banco puede negarse a
                procesar cualquier operación de retiro cuando el Usuario no
                cuente con saldo suficiente para cubrir el monto solicitado por
                el Usuario. Para llevar a cabo operaciones de Retiro el Usuario
                podrá realizarlo a través de un pago móvil a otro Banco. El
                Banco o PagoFlash puede en cualquier momento establecer o
                modificar limitaciones a las operaciones de retiro por razones
                de seguridad, para disminuir o controlar riesgos, o para dar
                cumplimiento a la normativa aplicable, inclusive mediante la
                fijación de montos mínimos o montos máximos por operación de
                pago móvil por día, semana, mes o año (salvo que el Usuario haya
                optado por cerrar su Cuenta, en cuyo caso podrá efectuar el pago
                móvil de los fondos disponibles en su Cuenta Transaccional, sin
                limitación, siguiendo para ello, el procedimiento previsto en
                estos Términos de Uso). Una vez que los fondos de la operación
                de pago móvil hayan sido debitados en la Cuenta Transaccional
                del Usuario la transacción será irreversible. Las operaciones de
                pago móvil están sujetas al cobro, por parte del Banco y
                PagoFlash, de las comisiones que se identifican en la sección
                “Comisiones” de en la página web www.pagoflash.com.
              </ListItem>
              <ListItem>
                Operaciones de Transferencias entre Usuarios, El Usuario puede
                en cualquier momento durante la vigencia de este Contrato enviar
                o recibir transferencias de dinero entre las Cuentas
                Transaccionales de PagoFlash. El Usuario no podrá enviar
                transferencias de dinero electrónico por montos superiores al
                saldo disponible. Al momento de cada transferencia, el Usuario
                deberá mantener un saldo suficiente para cubrir la transacción,
                además del importe de la transferencia, las Comisiones por
                transferencia que corresponden al Banco y a PagoFlash, en el
                momento de la transferencia. El Banco puede negarse a procesar
                cualquier operación de transferencia de dinero electrónico
                cuando el Usuario no cuente con saldo suficiente para cubrir el
                importe de la transferencia y las Comisiones de transferencia.
                Para más información sobre las Comisiones que cobra PagoFlash
                por los servicios de la App visite la sección “Comisiones” en la
                página web www.pagoflash.com.
              </ListItem>
              <ListItem>
                Usted podrá enviar o recibir transferencias de dinero
                electrónico utilizando la dirección de correo electrónico
                (e-mail) afiliada a su Cuenta. PagoFlash, pueden, en cualquier
                momento, establecer o modificar limitaciones a las operaciones
                de transferencia de dinero electrónico por razones de seguridad,
                para disminuir o controlar riesgos, o para dar cumplimiento a la
                normativa aplicable, inclusive mediante la fijación de montos
                mínimos o montos máximos por operación de transferencia, por
                día, semana, mes o por año. Los límites para transferencias
                podrán ser distintos a los límites establecidos para las
                operaciones de Recarga y pago móvil. Usted puede consultar los
                límites para transferencias en App. Los fondos, según
                corresponda, serán debitados o acreditados tan pronto la
                transacción sea confirmada por el Usuario según los pasos antes
                indicados. Usted recibirá una Notificación Electrónica cuando
                reciba transferencias de dinero electrónico en su Cuenta. Una
                vez que los fondos de la operación de transferencia estén
                acreditados en la cuenta del beneficiario de la transferencia la
                transacción será irreversible. Las operaciones de transferencia
                de dinero electrónico están sujetas al cobro, por PagoFlash de
                las comisiones que se identifican en la sección “Comisiones” en
                la página web www.pagoflash.com.
              </ListItem>
              <ListItem>
                Los fondos abonados a su Cuenta Transaccional no generan
                intereses de ninguna clase, ni tampoco rendimientos o ganancias
                de cualquier naturaleza.
              </ListItem>

              <ListItem>
                El Usuario y las transacciones u operaciones que el Usuario
                realiza a través de la App tienen fines lícitos y no viola la
                Ley Orgánica Contra la Delincuencia Organizada y Financiamiento
                al Terrorismo de Venezuela. Los fondos en Moneda Nacional que
                usted abona a su Cuenta Transaccional son propios de usted y
                provienen de fuentes legítimas y legales, y no son el producto
                de actividades directa o indirectamente vinculadas con el
                tráfico de sustancias o productos ilegales o prohibidos, el
                financiamiento del terrorismo, la legitimación de capitales, la
                corrupción en cualquier de sus formas o el fraude o la evasión
                fiscal.
              </ListItem>

              <ListItem>
                Este Contrato ha sido debidamente suscrito y otorgado por el
                Usuario y constituye una obligación legal, válida y vinculante
                para el Usuario.
              </ListItem>

              <ListItem>
                Tributos Aplicables a las Transacciones. El Usuario es
                responsable de determinar, calcular, pagar, declarar, retener,
                percibir o enterar, según corresponda, todos los tributos
                aplicables a las operaciones o transacciones realizadas por el
                Usuario a través de la App. Las transacciones que PagoFlash
                facilitan al Usuario son ejecutadas siguiendo las instrucciones
                dadas por el mismo a través de la App. En ningún momento
                PagoFlash será responsable de los tributos derivados o
                vinculados a las transacciones u operaciones de Recarga, Retiro,
                transferencia, pago o cobro ordenadas por los Usuarios, ni de
                cualquier otra transacción u operación efectuada por los
                Usuarios con la App.
              </ListItem>

              <ListItem>
                Limitación de Responsabilidad e Indemnización. En esta sección
                de Limitación de Responsabilidad e Indemnización, el término
                PagoFlash comprende no sólo la sociedad mercantil que
                representa, sino también sus entidades filiales o relacionadas,
                así como sus respectivos directores, administradores,
                accionistas, representantes legales o judiciales, agentes,
                empleados y dependientes. Ni el Banco ni PagoFlash serán
                responsables, y el Usuario mediante la aceptación de estos
                Términos de Uso libera de toda responsabilidad a ambos, por los
                daños y perjuicios directos, indirectos, incidentales o
                consecuenciales (incluyendo lucro cesante, punitivos, pérdida de
                valor o disminución de ganancias de bienes o propiedades),
                derivados o relacionados con: a) la ejecución de las órdenes o
                instrucciones que el Usuario haya dado a PagoFlash a través de
                la App, a menos que haya mediado Culpa Grave por parte del Banco
                y/o PagoFlash, b) La indisponibilidad o las interrupciones o
                suspensiones de la App o de los servicios prestados por el Banco
                y PagoFlash a través de la App y que escapen del control de los
                mismos; c) La conexión con el uso de la App, o la demora o
                imposibilidad de utilizar la App; d) El software, sistemas,
                programas y los productos y los servicios obtenidos a través de
                la App, o los virus, archivos corruptos, troyanos, malware,
                gusanos o algún software similar que afecte el Dispositivo
                Compatible o cualquier otro dispositivo del Usuario, la App o
                los servicios ofrecidos a través de la App; d) Daños al
                Dispositivo Compatible del Usuario derivado del uso de la App;
                o, e) Cualquier acceso no autorizado a su Cuenta.; f) El Usuario
                acepta indemnizar, defender y eximir de responsabilidad al Banco
                y a PagoFlash contra cualquier reclamo, demanda, pérdida o
                responsabilidad, incluyendo los honorarios de abogados y costos
                legales razonables incurridos por el Banco y/o PagoFlash en la
                defensa de sus derechos, que se deriven o que puedan derivarse,
                o que guarden relación con: (a) el incumplimiento por parte del
                Usuario de estos Términos de Uso; cualquier inconsistencia,
                incumplimiento o cualquier incongruencia en las declaraciones y
                aseveraciones del Usuario contenidas en estos Términos de Uso;
                cualquiera daño o perjuicio causado otros usuarios de la App o a
                cualquier tercero, independientemente de su causa; el uso
                indebido de la App; o cualquier violación a cualquier Ley
                aplicable al hacer uso o servirse de la App. La responsabilidad
                del Banco y/o PagoFlash por cualquier reclamo o por pérdidas o
                daños derivados o vinculados directa o indirectamente a la
                prestación de sus servicios de la App en ningún caso excederá
                del equivalente al monto total de las Comisiones netas que el
                Banco y/o PagoFlash haya recibido o cobrado al Usuario hasta la
                fecha en que el reclamo sea de cualquier forma notificado a
                PagoFlash. Esta limitación aplicará sin importar el carácter de
                la acción, ya sea contractual, extracontractual o de cualquier
                otro tipo.
              </ListItem>

              <ListItem>
                La indisponibilidad o las interrupciones o suspensiones de la
                App o de los servicios prestados por el Banco y PagoFlash a
                través de la App y que escapen del control de los mismos; c) La
                conexión con el uso de la App, o la demora o imposibilidad de
                utilizar la App; d) El software, sistemas, programas y los
                productos y los servicios obtenidos a través de la App, o los
                virus, archivos corruptos, troyanos, malware, gusanos o algún
                software similar que afecte el Dispositivo Compatible o
                cualquier otro dispositivo del Usuario, la App o los servicios
                ofrecidos a través de la App; d) Daños al Dispositivo Compatible
                del Usuario derivado del uso de la App; o, e) Cualquier acceso
                no autorizado a su Cuenta.; f) El Usuario acepta indemnizar,
                defender y eximir de responsabilidad al Banco y a PagoFlash
                contra cualquier reclamo, demanda, pérdida o responsabilidad,
                incluyendo los honorarios de abogados y costos legales
                razonables incurridos por el Banco y/o PagoFlash en la defensa
                de sus derechos, que se deriven o que puedan derivarse, o que
                guarden relación con: (a) el incumplimiento por parte del
                Usuario de estos Términos de Uso; cualquier inconsistencia,
                incumplimiento o cualquier incongruencia en las declaraciones y
                aseveraciones del Usuario contenidas en estos Términos de Uso;
                cualquiera daño o perjuicio causado otros usuarios de la App o a
                cualquier tercero, independientemente de su causa; el uso
                indebido de la App; o cualquier violación a cualquier Ley
                aplicable al hacer uso o servirse de la App. La responsabilidad
                del Banco y/o PagoFlash por cualquier reclamo o por pérdidas o
                daños derivados o vinculados directa o indirectamente a la
                prestación de sus servicios de la App en ningún caso excederá
                del equivalente al monto total de las Comisiones netas que el
                Banco y/o PagoFlash haya recibido o cobrado al Usuario hasta la
                fecha en que el reclamo sea de cualquier forma notificado a
                PagoFlash. Esta limitación aplicará sin importar el carácter de
                la acción, ya sea contractual, extracontractual o de cualquier
                otro tipo.
              </ListItem>

              <ListItem>
                Privacidad y Protección de Datos, su privacidad es importante
                para PagoFlash, es por ello que ambos obtendrán y se servirán de
                la información que usted le suministre para dar cumplimiento a
                la Ley aplicable, PagoFlash lleva un registro de las operaciones
                o transacciones realizadas por el Usuario a través de la App;
                hace un seguimiento activo de dichas transacciones y
                operaciones; y reporta eventos, transacciones u operaciones
                irregulares o sospechosas a la Unidad de Inteligencia Financiera
                (UNIF) del Ministerio del Poder Popular de Economía y Finanzas.
                En ningún caso PagoFlash serán responsable por los daños y
                perjuicios derivados o relacionados con el reporte de eventos,
                transacciones u operaciones irregulares o sospechosas a la
                Unidad de Inteligencia Financiera (UNIF).
              </ListItem>

              <ListItem>
                Comunicaciones entre Usuarios y PagoFlash, el Usuario entiende y
                acepta que las comunicaciones enviadas o recibidas por PagoFlash
                no tienen carácter confidencial. PagoFlash podrá comunicarse con
                usted acerca de su Cuenta, sus operaciones o transacciones y, en
                general, acerca de cualquier aspecto relacionado con la App o
                estos Términos de Uso por medio de comunicaciones electrónicas
                (“Notificaciones Electrónicas”) que pueden incluir mensajes de
                correo electrónico (e-mail) a la dirección del Usuario afiliada
                a su Cuenta (dirección utilizada para crear su Cuenta y
                registrarse en la App); o Push o notificaciones que el Usuario
                puede leer en su Dispositivo móvil. Las Notificaciones
                Electrónicas enviadas a la dirección de correo electrónico
                (e-mail) afiliada o registrada en su Cuenta se considerarán
                recibidas el mismo día si son recibidas en el buzón de entrada
                de su correo electrónico antes de las 5:30 p.m. de un Día Hábil.
                Si son recibidas en el buzón de entrada de su correo electrónico
                luego de las 5:30 p.m. de un Día Hábil, se considerarán
                recibidas el Día Hábil más próximo. El Usuario es responsable de
                mantener activa y operativa en todo momento su cuenta de correo
                electrónico (e-mail) asociada o registrada a su Cuenta.
                PagoFlash no será responsable por los daños o perjuicios
                derivados de la imposibilidad del Usuario de acceder, leer o
                recibir las Notificaciones Electrónicas enviadas. Usted puede
                comunicarse con PagoFlash en cualquier momento a través de los
                siguientes medios: enviando un correo electrónico al Centro de
                Atención al Cliente de PagoFlash a la siguiente dirección de
                correo electrónico: atenciónalcliente@pagoflash.com.
              </ListItem>

              <ListItem>
                Tramitación de Dudas, Denuncias y Reclamos Usted puede canalizar
                cualquier reclamo relacionado con su Cuenta en la App a través
                del centro de atención al cliente de PagoFlash enviando un
                correo electrónico atenciónalcliente@pagoflash.com. PagoFlash
                realizará sus mejores esfuerzos por atender y proporcionar
                respuesta o solución a su reclamo dentro los veinte (20) días
                continuos. Las controversias relacionadas con los reclamos
                realizados por el Usuario serán atendidas por la Unidad de
                Atención al Cliente de PagoFlash.
              </ListItem>

              <ListItem>
                Domicilio Para todos los efectos de este Contrato, se fija como
                domicilio de PagoFlash la ciudad de Caracas Venezuela.
              </ListItem>
            </OrderedList>
          </Text>
        </Box>

        {/* terminos anteriores */}
        {/* <Box maxW="4xl" mx="auto" boxShadow="base" py="8" px="16">
          <Heading fontWeight="thin" mb="8">
            Términos Generales de uso de la Aplicación Móvil "App PAGOFLASH"{" "}
          </Heading>
          <Text fontWeight="thin" fontSize="lg">
            El presente Acuerdo es un contrato entre usted y PAGOFLASH
            INTERNATIONAL C.A. Una compañía de la REPÚBLICA BOLIVARIANA DE
            VENEZUELA inscrita en el Registro Mercantil Segundo de la
            Circunscripción Judicial del Distrito Capital y Estado Miranda, en
            fecha diez (10) de junio del año dos mil trece (2013), bajo el N° 93
            Tomo 61-A-SDO, identificada con el registro único de información
            fiscal (RIF) j-40256916-5 y se aplica a la utilización de todos los
            Servicios PagoFlash. La utilización de los Servicios PagoFlash
            implica que usted debe aceptar todos los términos y condiciones
            contenidos en este Acuerdo, incluida la Política de Privacidad y la
            Política de Uso Aceptable. Usted debe leer detenidamente todos estos
            términos. Nos reservamos el derecho de modificar este Acuerdo en
            cualquier momento mediante la publicación de una versión revisada en
            nuestro sitio web. Dicha versión entrará en vigor en el momento de
            su publicación. Si la versión modificada incluye un cambio
            sustancial, se lo notificaremos con al menos quince (15) días de
            anticipación a dicho cambio sustancial mediante la publicación en la
            página. Todos los eventuales cambios previstos en la Actualización
            de la Política, ya publicados en la página de PagoFlash en el
            momento en que usted se inscribe en los Servicios PagoFlash, se
            incorporarán por referencia en este Acuerdo y entrarán en vigor tal
            como se especifica en la Actualización de la Política. El presente
            es un documento importante que usted debe leer detenidamente antes
            de decidir utilizar los Servicios PagoFlash.
            <Spacer my="4" />
            PRIMERO: OBJETO Y REGISTRO.
            <Spacer my="4" />
            <OrderedList className="nested-counter-list">
              <ListItem>
                EL OBJETO de este contrato es la prestación del servicio de
                intermediación de pago, mediante la gestión de un punto de venta
                electrónico por parte de PagoFlash al Comercio Aliado, para que
                los clientes de este último, personas naturales o jurídicas (en
                lo sucesivo los “Tarjetahabientes”) puedan comprar sus productos
                con tarjetas de crédito (en lo sucesivo las “Tarjetas”) (en lo
                sucesivo el “Servicio”). Nosotros no tenemos ningún control
                sobre los bienes o servicios pagados con nuestro servicio
                PagoFlash, ni somos responsables o respondemos por ellos.
                Nosotros no podemos garantizar que un comprador o vendedor con
                quien usted esté tratando realmente vaya a completar la
                transacción.
              </ListItem>
              <ListItem>
                REGISTRO: Usted deberá registrarse previamente en la plataforma
                web de PagoFlash, completando electrónicamente con datos
                exactos, verdaderos y actuales el formulario de registro
                suministrado a tal efecto, y crear su contraseña de acceso. De
                igual manera, están obligados a mantener actualizados sus datos
                de registro permanentemente.
                <OrderedList>
                  <ListItem>
                    PagoFlash se reserva el derecho a utilizar los medios
                    lícitos que estime pertinentes para verificar la identidad
                    del USUARIO, así como de requerir con dicho fin comprobantes
                    o cualquier información adicional a la solicitada en el
                    formulario de registro, cuando lo considere.{" "}
                  </ListItem>
                  <ListItem>
                    Una vez registrado, podrá acceder a su cuenta particular en
                    la plataforma de PagoFlash, ingresando sus datos e
                    incluyendo su contraseña de acceso. Usted está obligado a
                    mantener la confidencialidad de su contraseña de acceso y
                    asume que su cuenta es particular, única e intransferible,
                    de modo que todas las operaciones efectuadas desde su cuenta
                    particular son de su exclusiva responsabilidad.{" "}
                  </ListItem>
                  <ListItem>
                    PagoFlash se reserva el derecho de negar las solicitudes de
                    registro o de suspender de forma temporal o definitiva
                    cuando sus datos no sean posibles de verificar, sin que tal
                    suspensión le genere derecho a resarcimiento alguno.{" "}
                  </ListItem>

                  <ListItem>
                    PagoFlash no se hace responsable por la certeza de los datos
                    de registro proporcionado, usted garantiza y se hace
                    responsable de forma exclusiva por la exactitud, veracidad y
                    vigencia de sus datos de registro, así como de su capacidad
                    legal para contratar y cumplir con todas las obligaciones
                    contraídas, incluyendo con las contenidas en este Contrato,
                    y de su cumplimiento con las normas vigentes.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                INFORMACIÓN DE CONTACTO. Es su responsabilidad mantener
                actualizado el correo electrónico principal, de manera que
                PagoFlash pueda comunicarse con Usted de manera electrónica.
                Usted comprende y acepta que si PagoFlash le envía una
                comunicación electrónica y no la recibe debido a que su
                dirección principal de correo electrónico registrada es
                incorrecta, está desactualizada, está bloqueada por su proveedor
                de servicio, o por cualquier otro motivo no puede recibir
                comunicaciones electrónicas, PagoFlash considerará que dicha
                comunicación ha sido enviada efectivamente. Tenga en cuenta que
                si utiliza un filtro de correo no deseado que bloquea o desvía
                los correos electrónicos de los remitentes que no aparecen en su
                libreta de direcciones de correo electrónico, deberá agregar a
                PagoFlash a su libreta de direcciones para que pueda ver las
                comunicaciones que le enviamos.
              </ListItem>

              <ListItem>
                DEL SERVICIO El servicio a prestar por PagoFlash, supone, entre
                otras cosas, (i) la gestión del punto de venta en la plataforma
                electrónica del Comercio Aliado a fines de que los
                Tarjetahabientes puedan adquirir los bienes y servicios que les
                son ofrecidos mediante el uso de sus tarjetas de crédito; (ii)
                la recepción de los pagos por concepto de los bienes y servicios
                adquiridos mediante el uso de tarjetas de crédito en los puntos
                de venta; y (iii) la imputación de los pagos recibidos al
                Comercio Aliado.
              </ListItem>

              <ListItem>
                DE LA GESTIÓN DE LOS PUNTOS DE VENTA: PagoFlash se obliga a
                poner en funcionamiento un punto de venta electrónico como medio
                de pago en la página web del Comercio Aliado en caso de
                desearlo, o a través de la plataforma.
              </ListItem>

              <ListItem>
                DE LA RECEPCIÓN DE LOS PAGOS: PagoFlash se obliga a recibir por
                cuenta del Comercio Aliado los pagos que se generen como
                consecuencia de la adquisición de bienes y servicios por los
                Tarjetahabientes a través del punto de venta. En el ejercicio de
                esta obligación, PagoFlash manejará un sistema electrónico de
                organización del Comercio Aliado en el que debe reflejarse la
                información del adquirente, de los bienes o servicios
                adquiridos, la descripción o código de los bienes o servicios
                adquiridos, el monto recibido en el punto de venta electrónico,
                la fecha y hora exactas de la recepción del monto en el punto de
                venta electrónico, el comercio de destino de dichos fondos, y el
                código de la transacción, a fines de realizar la correspondiente
                imputación.
              </ListItem>

              <ListItem>
                DE LA IMPUTACIÓN DE LOS PAGOS:
                <OrderedList>
                  <ListItem>
                    PagoFlash se obliga a realizar la imputación y liberación de
                    los pagos recibidos al Comercio Aliado en un lapso no menor
                    a cuarenta y ocho (48) horas hábiles y no mayor a ocho (8)
                    días hábiles, contados a partir del día siguiente a la
                    recepción del pago. Estos plazos son establecidos bajo las
                    políticas de seguridad de la empresa. PagoFlash notificará
                    al Comercio Aliado de los montos recibidos en el punto de
                    venta que les sean imputables en un lapso no mayor a ocho
                    (8) días hábiles.
                  </ListItem>

                  <ListItem>
                    El Comercio Aliado se obliga a proporcionar a PagoFlash
                    todas las informaciones que sean requeridas a efectos de la
                    recepción e imputación de los pagos que sean procesados por
                    los Puntos de Venta, incluyendo pero sin que constituya
                    limitación, información sobre descripciones o códigos de sus
                    bienes y servicios, informaciones básicas sobre las cuentas
                    bancarias requeridas para la atribución de los montos
                    correspondientes, y en general, todo cuanto PagoFlash
                    considere necesario para la efectiva realización de sus
                    obligaciones.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                PagoFlash podrá solicitar comprobantes por transacciones de pago
                firmados por el tarjehabiente.
                <OrderedList>
                  <ListItem>
                    DE LA COMISIÓN: El Comercio Aliado pagará a PagoFlash una
                    comisión como contraprestación al servicio prestado, la cual
                    será cobrada por PagoFlash al momento de realizar la
                    liberación de pago, este costo estará en un rango entre seis
                    con cinco por ciento (6,5%) y diez con cinco (10,5%)
                    incluido el IVA. Queda a discreción de PagoFlash el monto
                    otorgado al Comercio Aliado, según sus criterios de
                    segmentación. Usted se obliga a no imponer recargo alguno o
                    cualquier otra comisión por aceptar a PagoFlash como forma
                    de pago.
                  </ListItem>

                  <ListItem>
                    DE LOS COMPROBANTES POR LA PRESTACIÓN DEL SERVICIO: Todo
                    pago realizado a través del punto de venta generará un
                    comprobante de la transacción para el Tarjetahabiente. Es
                    responsabilidad del Comercio Aliado emitir y enviar la
                    correspondiente factura fiscal por cada venta, así como
                    generar el respectivo comprobante de recibo. El comprobante
                    de recibo podrá ser requerido por PagoFlash en cualquier
                    momento.
                  </ListItem>

                  <ListItem>
                    DEL FRAUDE ELECTRÓNICO Y OTROS ILÍCITOS: En caso de
                    investigaciones por fraudes electrónicos u otros ilícitos,
                    la omisión del envío o el envío tardío de los comprobantes
                    de recibo por parte del Comercio Aliado a PagoFlash, podrá
                    dar lugar a la exención de la obligación de liberación de
                    pago por parte de PagoFlash. En todo caso, el Comercio
                    Aliado comprende y acepta que las cantidades de dinero
                    objeto de las transacciones por los puntos de venta pueden
                    ser retenidas por la entidad bancaria con ocasión de
                    investigaciones por fraudes electrónicos u otros ilícitos,
                    casos en los cuales PagoFlash no estará obligado a cumplir
                    con los plazos de imputación y liberación de pago previsto
                    en este Contrato, hasta que se solvente el inconveniente.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                PROTECCIÓN Y TRANSACCIONES NO AUTORIZADAS.
                <OrderedList>
                  <ListItem>
                    Cuando ocurre una transacción no autorizada en su cuenta,
                    PagoFlash cubrirá el importe total de la o las transacciones
                    no autorizadas que reúnan los requisitos aplicables, siempre
                    que usted siga los procedimientos que se describen a
                    continuación. Una Transacción no Autorizada es un pago que
                    usted no autorizó y que no lo beneficia. Por ejemplo, si
                    alguien roba los datos de su tarjeta de crédito y los
                    utiliza para realizar pagos a través de nuestra plataforma,
                    entonces ha ocurrido una Transacción no Autorizada. a) Se
                    verificó la realización de una Transacción no Autorizada. b)
                    Existe un Error en la confirmación de la transacción que se
                    le envió por correo electrónico. c) Necesita más información
                    acerca de una transacción que aparece en el estado de cuenta
                    o en la confirmación de transacción. d) El importe de la
                    supuesta Transacción no Autorizada. e) PagoFlash podrá
                    solicitarle que nos envíe su queja o pregunta por escrito en
                    el plazo de cinco (5) días hábiles. Durante el curso de la
                    investigación, podemos solicitarle información adicional.
                  </ListItem>

                  <ListItem>
                    {" "}
                    MEDIDAS DE PAGOFLASH UNA VEZ RECIBIDA SU NOTIFICACIÓN: Una
                    vez que usted nos notifique acerca de presuntas
                    Transacciones no Autorizadas, o si nos enteramos de ello de
                    alguna otra forma, seguiremos el siguiente procedimiento: a)
                    Se realizará una investigación para determinar si se ha
                    realizado una Transacción no Autorizada que reúna los
                    requisitos para la protección. b) Realizaremos la
                    investigación en el plazo de quince (15) días hábiles a
                    partir de la fecha en que recibimos su notificación de
                    Transacción no Autorizada. En caso de requerir más tiempo,
                    podríamos tardar hasta cuarenta y cinco (45) días en
                    concluir la investigación, ó hasta noventa (90) días para
                    cuentas nuevas. c) Le participaremos nuestra decisión en un
                    lapso de tres (3) días hábiles a partir de haber concluido
                    la investigación. Si establecemos que hubo una Transacción
                    no Autorizada, restituiremos el importe total de su reclamo
                    en un lapso de un (1) día hábil a partir de nuestra
                    determinación. Si determinamos que no hubo una Transacción
                    no Autorizada, incluiremos una explicación acerca de nuestra
                    decisión en el correo electrónico que le enviemos. Puede
                    solicitar copias de los documentos que utilizamos en la
                    investigación.{" "}
                  </ListItem>

                  <ListItem>
                    ERRORES DE PAGOFLASH. Nosotros rectificaremos cualquier
                    Error que descubramos. Si el Error provoca que Usted reciba
                    un importe inferior al que le corresponde, PagoFlash abonará
                    en su Cuenta la diferencia. Si el Error provoca que reciba
                    un importe mayor del que le corresponde, PagoFlash puede
                    debitar los fondos adicionales de su Cuenta. 9.4 SUS
                    ERRORES. Si usted erróneamente realiza el pago a un tercero
                    equivocado, o envía un pago por un importe incorrecto (por
                    ejemplo, debido a un error tipográfico), su único recurso
                    será ponerse en contacto con el tercero a quien le envió el
                    pago y solicitarle un reembolso del mismo. PagoFlash no
                    reembolsará ni revertirá los pagos que haya realizado por
                    error.{" "}
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                ACTIVIDADES RESTRINGIDAS ACTIVIDADES RESTRINGIDAS: En relación
                con la utilización que usted realice de nuestro sitio web, su
                Cuenta, los Servicios PagoFlash o en el transcurso de sus
                interacciones con PagoFlash, un Usuario, o terceros, usted no
                deberá: a. Incumplir este Acuerdo, el Acuerdo de Entidad
                Comercial, la Política de Uso Aceptable o cualquier otra
                Política de PagoFlash que haya aceptado; b. Infringir cualquier
                Ley, estatuto, disposición ordenanza o reglamento, previstos en
                el ordenamiento jurídico venezolano. c. Infringir los derechos
                de autor, patente, marca comercial, secreto comercial u otros
                derechos de propiedad intelectual de PagoFlash o de cualquier
                otro tercero o los derechos de publicidad o privacidad; d.
                Vender artículos falsificados; e. Actuar de una manera que sea
                difamatoria, amenazante u hostil con nuestros empleados, agentes
                u otros Usuarios; f. Proporcionar Información falsa, inexacta o
                engañosa; g. Involucrarse en actividades y/o transacciones
                potencialmente fraudulentas o sospechosas; h. Negarse a cooperar
                en una investigación o a proporcionar confirmación de su
                identidad o cualquier otra Información que usted nos
                proporcione; i.Controlar una Cuenta que está asociada a otra
                Cuenta que se haya visto involucrada en cualquiera de estas
                actividades restringidas; j.Conducir su empresa o utilizar los
                Servicios PagoFlash de manera tal que tenga o pueda tener como
                resultado quejas, Controversias, Reclamaciones, Cancelaciones,
                Devoluciones de Cargo, Comisiones, Multas, Penalizaciones y
                otras responsabilidades ante PagoFlash, otros usuarios, terceros
                o usted; k. Tener una calificación de riesgo crediticio de una
                agencia de informe de crédito que indique un alto nivel de
                riesgo asociado a su utilización de los Servicios PagoFlash;
                l.Utilizar su Cuenta o los Servicios PagoFlash de manera tal que
                PagoFlash, Visa, MasterCard, American Express, o cualquier otra
                red de transferencia electrónica de fondos crea razonablemente
                que ha habido un abuso del sistema de tarjetas o una
                transgresión a la asociación de tarjetas o de las reglas de la
                red; m.Usar una tarjeta de crédito en su Cuenta para otorgarse a
                sí mismo un anticipo en efectivo (o ayudar a otros a realizar
                esta acción); n. Obtener acceso a los Servicios PagoFlash desde
                otro país. o. Revelar o distribuir Información de otros Usuarios
                a terceros o utilizar la Información con fines comerciales, a
                menos que reciba el consentimiento expreso del Usuario para
                hacerlo; p. Enviar correos electrónicos no solicitados a
                Usuarios ni utilizar los Servicios PagoFlash para cobrar
                cantidad alguna por enviar, o ayudar a enviar, correos
                electrónicos no solicitados a terceros; q. Adoptar medidas que
                impongan una carga no razonable o desproporcionadamente grande
                en nuestra infraestructura; r. Utilizar un proxy anónimo;
                utilizar robots, arañas y otros dispositivos automáticos o
                procesos manuales para controlar o copiar nuestros sitios web
                sin nuestro consentimiento previo por escrito; s. Utilizar
                dispositivos, software o rutinas para infringir las
                restricciones de nuestros encabezados de exclusión de robots o
                para interferir o intentar interferir con nuestro sitio web o
                los Servicios PagoFlash; t. Adoptar medidas que pueden provocar
                que perdamos cualquiera de los servicios de nuestros proveedores
                de servicios de internet, procesadores de pago u otros
                proveedores.
              </ListItem>

              <ListItem>
                CANCELACIÓN DE SU CUENTA
                <OrderedList>
                  <ListItem>
                    CÓMO CANCELAR SU CUENTA: Usted puede cancelar su Cuenta en
                    cualquier momento siguiendo las instrucciones del Perfil de
                    Cuenta. Después del cierre de una cuenta, se cancelará
                    cualquier transacción en trámite.
                  </ListItem>
                  <ListItem>
                    RESTRICCIONES DEL CIERRE DE LA CUENTA: Usted no puede evadir
                    ninguna investigación mediante la cancelación de su Cuenta.
                    Si cancela su Cuenta mientras nosotros estamos llevando a
                    cabo una investigación, nosotros podemos retener sus fondos
                    para proteger a PagoFlash, sus filiales o a terceros, contra
                    el riesgo de Reversiones, Contracargos, Reclamaciones,
                    Comisiones, Multas, Penalizaciones y otras
                    responsabilidades. Usted seguirá siendo responsable de todas
                    las obligaciones relacionadas con su Cuenta, incluso después
                    de la cancelación de la misma.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                RESPONSABILIDAD DE PAGOFLASH:
                <OrderedList>
                  <ListItem>
                    PagoFlash únicamente será responsable por el cumplimiento de
                    sus obligaciones en el marco del presente Contrato, salvo en
                    los casos en los que por cualquier causa extraña que no le
                    sea imputable, le sea imposible la realización de sus
                    obligaciones.
                  </ListItem>
                  <ListItem>
                    PagoFlash no se hará responsable por cualesquiera
                    inconvenientes que pudieren surgir en el procedimiento de
                    pago de los bienes y servicios por parte de los
                    Tarjetahabientes, incluyendo, pero sin que constituya
                    limitación, la falta de información que deban proporcionar
                    los Tarjetahabientes, información errada, fondos
                    insuficientes o, en general, cualquier causal de rechazo del
                    pago por tarjeta. PagoFlash únicamente será responsable una
                    vez recibido el pago en el punto de venta por parte de los
                    Tarjetahabientes en el marco de sus obligaciones.
                  </ListItem>
                  <ListItem>
                    PagoFlash no se hará responsable por cualesquiera
                    inconvenientes bancarios, técnicos o tecnológicos que no le
                    sean imputables directa o indirectamente que pudieren surgir
                    en la liberación de los pagos que correspondan al Comercio
                    Aliado. PagoFlash se compromete a realizar la liberación del
                    pago al Comercio Aliado tan pronto sean subsanados dichos
                    inconvenientes.
                  </ListItem>
                  <ListItem>
                    PagoFlash no será responsable por la prestación de bienes y
                    servicios que realice el Comercio Aliado respecto de sus
                    compradores. En ese sentido, PagoFlash no será en ninguna
                    medida responsable junto con el Comercio Aliado por
                    cualquier reclamación que se efectúe en su contra en razón
                    de la prestación de sus bienes y servicios. PagoFlash no se
                    hace responsable por cualquier queja, demanda o acción legal
                    que pudiera ser intentada en su contra relacionadas con la
                    venta de productos por parte del Comercio Aliado, el cual
                    además deberá indemnizar a PagoFlash por todas las pérdidas,
                    costos, gastos y honorarios de abogados en que incurriese
                    por tal motivo, derecho que perdurarán aún después de
                    terminado el contrato.
                  </ListItem>
                  <ListItem>
                    PagoFlash no será responsable por las devoluciones a que
                    hubiere lugar por concepto de reclamaciones contra el
                    Comercio Aliado. El Servicio prestado por PagoFlash al
                    Comercio Aliado no incluye la devolución de los fondos, que
                    será responsabilidad exclusiva del Comercio Aliado respecto
                    de sus clientes.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                DE LA PROTECCIÓN DE DATOS Y CONFIDENCIALIDAD: La información
                personal proporcionada en la plataforma de PagoFlash se procesa
                y almacena en servidores o medios magnéticos que mantienen altos
                estándares de seguridad y protección física y tecnológica.
                PagoFlash no transmitirá ni dispondrá libremente de la
                información confidencial suministrada por el Comercio Aliado ni
                por los Tarjetahabientes.
                <OrderedList>
                  <ListItem>
                    Servicio Técnico del Punto de Venta y Responsabilidad por el
                    Funcionamiento de la Página: PagoFlash se compromete a hacer
                    mantenimiento y servicio técnico oportuno al punto de venta
                    electrónico dispuesto en las páginas web del Comercio
                    Aliado. PagoFlash no garantiza el acceso y uso continuado e
                    ininterrumpido del punto de venta, aunque declara que
                    procurará solucionar las fallas ordinarias o extraordinarias
                    que puedan presentarse con ocasión de fallas en los
                    sistemas, en los servicios de internet o por cualquier otra
                    circunstancia. El Comercio Aliado se obliga a proporcionar a
                    PagoFlash todas las informaciones técnicas y tecnológicas
                    que sean requeridas a efectos de la eficaz puesta en
                    funcionamiento de los puntos de venta electrónicos en sus
                    portales web, así como la realización del mantenimiento y
                    servicio técnico. Además, se compromete a hacer
                    mantenimiento y seguimiento al portal web de modo que pueda
                    accederse con facilidad al punto de venta para la
                    realización de pagos electrónicos. PagoFlash no será
                    responsable por los desperfectos técnicos y tecnológicos del
                    portal web del Comercio Aliado de modo que dificulten o
                    inhabiliten el acceso al punto de venta para la realización
                    de pagos electrónicos por parte de los Tarjetahabientes.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                SU RESPONSABILIDAD - MEDIDAS QUE NOSOTROS PODEMOS ADOPTAR.
                <OrderedList>
                  <ListItem>
                    SU RESPONSABILIDAD. a. General. Usted es responsable de
                    todas las Reversiones, Contracargos, Reclamaciones,
                    Comisiones, Multas, Penalizaciones y otras responsabilidades
                    en las que incurra PagoFlash, un Usuario o un tercero
                    causadas por, o derivadas de, el uso que usted haya hecho de
                    los Servicios PagoFlash y/o causadas por un incumplimiento
                    de este Acuerdo por parte de usted. Acepta reembolsar a
                    PagoFlash, Usuario de PagoFlash o tercero por cualquiera y
                    cada una de dichas responsabilidades. b. Responsabilidad por
                    las Instrucciones Dadas por Usted en su Cuenta. PagoFlash se
                    basará en cualquier instrucción que usted haya dado en su
                    Cuenta (ya sea de forma verbal o por escrito) una vez que se
                    haya autenticado. PagoFlash no será responsable de pérdidas
                    o daños que usted o cualquier otra persona pueda sufrir
                    cuando PagoFlash actúe de buena fe de conformidad con dichas
                    instrucciones, a menos que se demuestre negligencia por
                    parte de PagoFlash.
                  </ListItem>

                  <ListItem>
                    REMBOLSO POR SU RESPONSABILIDAD: En caso de que usted sea
                    responsable por importes adeudados a PagoFlash, PagoFlash
                    retirará inmediatamente dichos importes de su saldo.
                  </ListItem>

                  <ListItem>
                    MEDIDAS DE PAGOFLASH: actividades restringidas. Si
                    PagoFlash, a su entera discreción, considera que usted se ha
                    visto involucrado en Actividades Restringidas, nosotros
                    podemos adoptar varias medidas para proteger a PagoFlash,
                    Usuarios o a terceros, contra Reversiones, Contracargos,
                    Reclamaciones, Comisiones, Multas, Penalizaciones y
                    cualquier otra responsabilidad. Entre las medidas que
                    nosotros podemos adoptar se incluyen, pero no se limitan a:
                    a. Cancelar, suspender o limitar el acceso a su Cuenta o a
                    los Servicios PagoFlash; b. Negarnos a proporcionarle los
                    Servicios PagoFlash ahora y en el futuro; y retener sus
                    fondos por un período de tiempo razonablemente necesario
                    para protegernos contra el riesgo de responsabilidad ante
                    PagoFlash o un tercero; o si creemos que puede estar
                    involucrado en actividades o transacciones potencialmente
                    sospechosas o fraudulentas.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                CONTROVERSIAS CON PAGOFLASH.
                <OrderedList>
                  <ListItem>
                    PRIMERO COMUNÍQUESE CON PAGOFLASH: Si surgiera una
                    controversia entre Usted y PagoFlash, nuestro objetivo es
                    conocer y abordar sus problemas y, si no logramos hacerlo de
                    manera que Usted quede satisfecho, proporcionarle un medio
                    neutral y eficiente para resolver la controversia
                    rápidamente. Se pueden reportar las Controversias entre
                    usted y PagoFlash en relación con los Servicios PagoFlash al
                    Servicio de atención al cliente en línea a través del Centro
                    de ayuda de PagoFlash.
                  </ListItem>

                  <ListItem>
                    USTED ACEPTA que toda reclamación o controversia que pueda
                    tener contra PagoFlash deba ser resuelta en los Tribunales
                    de la Jurisdicción del Área Metropolitana de Caracas. Este
                    Acuerdo está regido en todos sus aspectos bajo las Leyes de
                    LA REPUBLICA BOLIVARIANA DE VENEZUELA.
                  </ListItem>

                  <ListItem>
                    LITIGIO PRESENTADO INADECUADAMENTE: Todas las reclamaciones
                    que presente contra PagoFlash deben resolverse conforme al
                    Artículo quince (15) de este Acuerdo. Todas las
                    Reclamaciones presentadas o traídas de manera contraria a la
                    Sección quince (15) se considerarán haber sido presentadas
                    de manera inadecuada y haber incumplido este Acuerdo. Si
                    presenta una Reclamación contraria a la Sección quince (15),
                    PagoFlash podrá recuperar los honorarios del abogado
                    (incluso los abogados internos de PagoFlash y asistentes
                    legales) y los costos, siempre y cuando PagoFlash le haya
                    notificado por escrito de la reclamación presentada
                    inadecuadamente y usted no la haya retirado oportunamente.
                  </ListItem>

                  <ListItem>
                    NOTIFICACIONES A SU ATENCIÓN: Usted acepta que PagoFlash
                    puede enviarle comunicaciones electrónicas con respecto a su
                    Cuenta, los Servicios PagoFlash y este Acuerdo. PagoFlash se
                    reserva el derecho a cerrar su Cuenta si retira su
                    consentimiento para recibir comunicaciones electrónicas. Se
                    considerará que todas las comunicaciones electrónicas son
                    recibidas por usted dentro de veinticuatro (24) horas desde
                    el momento en que las publicamos en nuestro sitio Web o se
                    las hayamos enviado por correo electrónico. Cualquier aviso
                    que se le envíe por correo postal será considerado como
                    recibido después de tres (3) días hábiles de ser enviado.
                  </ListItem>

                  <ListItem>
                    PROCEDIMIENTOS DE QUIEBRA: Si Usted inicia o se inicia un
                    proceso judicial en su contra de conformidad con alguna
                    disposición de una Ley de quiebra o concurso mercantil,
                    PagoFlash tendrá derecho a recuperar todos los costos y
                    gastos razonables (incluidos los honorarios y gastos de
                    abogado) en que se haya incurrido de conformidad con la
                    ejecución de este Acuerdo. 15.6 EXENCIÓN DE PAGOFLASH. Si
                    Usted tiene una controversia con uno o más Usuarios, Usted
                    libera a PagoFlash (y sus funcionarios, directores, agentes,
                    empresas de participación conjuntas, empleados y
                    proveedores) de cualesquier reclamación, demanda y daño
                    (directo o indirecto) de cualquier tipo y naturaleza que
                    pudieran derivarse de dichas controversias o tengan
                    cualquier relación con las mismas. TÉRMINOS GENERALES.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                LIMITACIONES DE RESPONSABILIDAD: BAJO NINGUNA CIRCUNSTANCIA
                NOSOTROS, PAGOFLASH, NUESTROS FUNCIONARIOS, DIRECTORES, AGENTES,
                EMPRESAS CONJUNTAS, EMPLEADOS O PROVEEDORES, SEREMOS
                RESPONSABLES POR LA PÉRDIDA DE GANANCIAS O POR CUALQUIER DAÑO
                ESPECIAL, INCIDENTAL O CONSECUENTE (INCLUYENDO, SIN LIMITAR, LOS
                DAÑOS POR LA PÉRDIDA DE DATOS O PÉRDIDA DE ACTIVIDAD COMERCIAL)
                DERIVADO DE, O EN CONEXIÓN CON, NUESTRO SITIO WEB, LOS SERVICIOS
                PAGOFLASH O ESTE ACUERDO (CUALQUIERA QUE SEA SU ORIGEN,
                INCLUYENDO NEGLIGENCIA), A MENOS QUE, Y EN LA MEDIDA EN QUE NO
                ESTÉ PROHIBIDO POR LEY, NUESTRA RESPONSABILIDAD Y LA
                RESPONSABILIDAD DE NUESTRA CASA MATRIZ, NUESTROS FUNCIONARIOS,
                DIRECTORES, AGENTES, EMPRESAS CONJUNTAS, EMPLEADOS Y
                PROVEEDORES, ANTE USTED O CUALQUIER TERCERO EN CUALQUIER
                CIRCUNSTANCIA ESTÉ LIMITADA AL IMPORTE REAL DE LOS DAÑOS
                DIRECTOS.
              </ListItem>

              <ListItem>
                LIMITACIONES DE RESPONSABILIDAD: BAJO NINGUNA CIRCUNSTANCIA
                NOSOTROS, PAGOFLASH, NUESTROS FUNCIONARIOS, DIRECTORES, AGENTES,
                EMPRESAS CONJUNTAS, EMPLEADOS O PROVEEDORES, SEREMOS
                RESPONSABLES POR LA PÉRDIDA DE GANANCIAS O POR CUALQUIER DAÑO
                ESPECIAL, INCIDENTAL O CONSECUENTE (INCLUYENDO, SIN LIMITAR, LOS
                DAÑOS POR LA PÉRDIDA DE DATOS O PÉRDIDA DE ACTIVIDAD COMERCIAL)
                DERIVADO DE, O EN CONEXIÓN CON, NUESTRO SITIO WEB, LOS SERVICIOS
                PAGOFLASH O ESTE ACUERDO (CUALQUIERA QUE SEA SU ORIGEN,
                INCLUYENDO NEGLIGENCIA), A MENOS QUE, Y EN LA MEDIDA EN QUE NO
                ESTÉ PROHIBIDO POR LEY, NUESTRA RESPONSABILIDAD Y LA
                RESPONSABILIDAD DE NUESTRA CASA MATRIZ, NUESTROS FUNCIONARIOS,
                DIRECTORES, AGENTES, EMPRESAS CONJUNTAS, EMPLEADOS Y
                PROVEEDORES, ANTE USTED O CUALQUIER TERCERO EN CUALQUIER
                CIRCUNSTANCIA ESTÉ LIMITADA AL IMPORTE REAL DE LOS DAÑOS
                DIRECTOS.
              </ListItem>

              <ListItem>
                LIMITACIÓN DE LOS SERVICIOS: PagoFlash no es un banco y los
                Servicios PagoFlash son servicios de intermediación de pagos, y
                no servicios bancarios. PagoFlash no actúa como fideicomisario,
                fiduciario o depositario con respecto a sus fondos, sino como
                agente y custodio. PagoFlash no tiene el control ni es
                responsable de los productos o servicios pagados a través de los
                servicios PagoFlash. No podemos garantizar la identidad de
                ningún usuario ni asegurar que un comprador o vendedor realizará
                una transacción.
              </ListItem>

              <ListItem>
                EXCLUSIÓN DE GARANTÍA: LOS SERVICIOS PagoFlash SE PROPORCIONAN
                “TAL CUAL” Y SIN GARANTÍA DE NINGÚN TIPO, YA SEA EXPRESA,
                IMPLÍCITA O ESTATUTARIA. PAGOFLASH, NUESTRA CASA MATRIZ,
                NUESTROS TRABAJADORES, DIRECTORES, AGENTES, EMPRESAS CONJUNTAS,
                EMPLEADOS Y PROVEEDORES, RENUNCIAMOS ESPECÍFICAMENTE A CUALQUIER
                GARANTÍA IMPLÍCITA DE TITULARIDAD, COMERCIABILIDAD, IDONEIDAD
                PARA UN FIN DETERMINADO Y AUSENCIA DE INFRACCIÓN. PagoFlash no
                tiene ningún control sobre los productos o servicios pagados
                mediante nuestro Servicio, ni puede garantizar que el comprador
                o vendedor con quien está tratando llegue a completar la
                transacción o esté autorizado para ello. PagoFlash no garantiza
                acceso continuo, ininterrumpido o seguro a nuestro Servicio ni a
                ninguna parte de los Servicios PagoFlash, y el funcionamiento de
                nuestro sitio web podría suspenderse temporalmente por
                mantenimiento o actualización, o puede verse afectado por
                diversos factores fuera de nuestro control. PagoFlash no hace
                declaraciones ni otorga garantías con respecto a la cantidad de
                tiempo necesario para concluir el procesamiento, porque los
                Servicios PagoFlash dependen en gran medida de varios factores
                fuera de su control, tales como retrasos en el sistema bancario
                o en servicios externos a nosotros.
              </ListItem>

              <ListItem>
                INDEMNIZACIÓN: Usted acepta defender, indemnizar y sacar a
                PagoFlash y a nuestros funcionarios, directores, agentes,
                empresas de participación conjunta, empleados y proveedores de
                cualquier responsabilidad ante cualquier reclamación, demanda
                (incluso los honorarios de abogados), multas o cualquier otra
                responsabilidad en la que haya incurrido algún tercero que surja
                del incumplimiento de Usted (o el incumplimiento de sus
                empleados o agentes) de este Acuerdo y/o del uso de los
                Servicios PagoFlash.
              </ListItem>

              <ListItem>
                LICENCIA DE COMERCIOS A PAGOFLASH. Si Usted es un Comercio que
                usa los Servicios PagoFlash, mediante el presente documento
                Usted nos concede un derecho mundial, no exclusivo, transferible
                y gratuito para usar y mostrar públicamente, durante el periodo
                de vigencia de este Acuerdo, sus marcas comerciales (de forma
                enunciativa mas no limitativa, marcas registradas y no
                registradas, nombres comerciales, marcas de servicio, logotipos,
                nombres de dominio y otras designaciones de su propiedad, que se
                le hayan licenciado o que usted use) con fines de (i)
                identificarlo a Usted como un Comercio que acepta un Servicio
                PagoFlash como forma de pago, y (ii) cualquier otro uso que
                usted consienta específicamente
              </ListItem>

              <ListItem>
                LLAMADAS A SU NÚMERO DE TELÉFONO MÓVIL. Al proporcionar a
                PagoFlash un número de teléfono (incluido un número de celular),
                da consentimiento a recibir en ese número llamadas de PagoFlash,
                incluido aquel de marcación automática y mensajes grabados
                previamente. Si determinamos que el número de teléfono que
                proporcionó es un número de celular, podemos categorizarlo como
                tal en nuestros sistemas y en su Perfil de Cuenta, y da
                consentimiento a recibir mensajes de texto de nuestra parte
                acerca del uso de los Servicios PagoFlash en ese número.
              </ListItem>

              <ListItem>
                MERCADEO: Si Usted recibe Información acerca de otro Usuario a
                través de los Servicios PagoFlash, Usted debe mantener la
                confidencialidad de esa Información y sólo utilizarla en
                relación con los Servicios PagoFlash. Usted no podrá revelar o
                distribuir la Información de un Usuario a terceros o utilizar la
                Información para fines de mercadeo, a menos que Usted reciba el
                consentimiento expreso del Usuario para hacerlo.
              </ListItem>

              <ListItem>
                SEGURIDAD DE LA CONTRASEÑA: Usted es responsable de mantener la
                adecuada seguridad y control de cualquiera de las
                identificaciones, contraseñas, números de identificación
                personal o de cualquier otro código que Usted utilice para
                obtener acceso a los Servicios PagoFlash.
              </ListItem>

              <ListItem>
                IMPUESTOS: Es su responsabilidad determinar qué impuestos, si
                los hay, se aplican a los pagos que hace o recibe, así como
                también recaudar, reportar y remitir el impuesto correcto a la
                autoridad fiscal pertinente. PagoFlash no es responsable de
                determinar si se aplican impuestos a su transacción, ni de
                recaudar, reportar o remitir ningún impuesto que surja de
                cualquier transacción. Tenga en cuenta que puede estar sujeto a
                retenciones de impuestos o responsabilidades tributarias en
                relación con el importe de servicios. Además, puede estar sujeto
                a IVA, impuestos sobre ventas, impuestos sobre la renta u otras
                responsabilidades tributarias como vendedor de productos o
                servicios. Es su responsabilidad verificar con su Asesor Fiscal
                para determinar qué impuestos se aplican a usted, así como
                también pagar dichos impuestos a la autoridad fiscal pertinente.
                Todas las Comisiones relacionadas con los Servicios PagoFlash
                están exentas de, y no tienen ninguna deducción o retención para
                y a Cuenta de, impuestos, derechos u otras deducciones. Es de su
                exclusiva responsabilidad hacerse cargo de las deducciones o
                retenciones exigibles por las leyes venezolanas.
              </ListItem>

              <ListItem>
                ACUERDO TOTAL Y VIGENCIA. Este Acuerdo, junto con cualquier
                Política aplicable en el sitio web de PagoFlash se establece
                entre Usted y PagoFlash en relación con sus servicios. En lo
                correspondiente a Cierre de su Cuenta, Su responsabilidad,
                medidas que podemos adoptar, Controversias con PagoFlash,
                Términos generales, así como cualquier otro término que debe
                subsistir por su naturaleza, perdurará a la cancelación de este
                Acuerdo. Si alguna disposición de este Acuerdo se invalida o no
                es aplicable, dicha disposición se suprimirá y se deberán
                aplicar las disposiciones restantes.
              </ListItem>

              <ListItem>
                CESIÓN: Usted no puede transferir ni ceder los derechos u
                obligaciones que tenga en virtud de este Acuerdo sin el previo
                consentimiento por escrito de PagoFlash. PagoFlash se reserva el
                derecho de transferir o asignar este Acuerdo o cualquier derecho
                u obligación de este Acuerdo en cualquier momento.
              </ListItem>

              <ListItem>
                NO RENUNCIA: Nuestra omisión o retraso en el ejercicio de las
                acciones correspondientes ante un incumplimiento de Usted o por
                otros no significará una renuncia a nuestro derecho de ejercer
                tales acciones con respecto a tal incumplimiento o un
                incumplimiento posterior o similar.
              </ListItem>

              <ListItem>
                SUBROGACIÓN DE DERECHOS. Si PagoFlash paga una Reclamación,
                Reversión o Contracargo que Usted haya presentado en contra de
                un destinatario de su pago, Usted acepta que PagoFlash se
                subrogará en sus derechos en contra del destinatario y de
                terceros en relación con el pago, y que ejerza dichos derechos
                directamente o en su nombre, a discreción de PagoFlash.
              </ListItem>
            </OrderedList>
          </Text>
        </Box> */}
      </Box>

      <Footer />
    </Box>
  );
};

export default TermsAndConditionsPage;
