import slideZuliatect from "../assets/images/slider-zuliatect.png";
import slideCarbone from "../assets/images/slider-carbone.jpg";
import slideLaCasaDelArbol from "../assets/images/slider-lacasadelarbol.png";
import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { DeviceFramesetProps } from "react-device-frameset";
import { routes } from "../routes/links";

const imgDeviceLaptop = require("../assets/images/imgDeviceLaptop.jpg");
const imgDeviceTablet = require("../assets/images/imgDeviceTablet.jpg");
const imgDevicePhone = require("../assets/images/imgDevicePhone.jpg");

const imgMailPhone = require("../assets/images/PagoMailMobile.png");

const imgETFC1 = require("../assets/images/imgETFC1.png");
const imgETFC3 = require("../assets/images/imgETFC3.png");

const pagoFlashWallet = require("../assets/images/pagowallet.png");
const pagoFlahBusiness = require("../assets/images/pagobusiness.png");
const pagoFlashCommerce = require("../assets/images/pagocommerce.png");

interface HowToUseLink {
  text?: string;
  link?: string;
}
interface HowToUseItem {
  title: string;
  links: HowToUseLink[];
  image: string;
  description: string;
}

interface DoYouKnowDescription {
  text: string;
  highlight?: boolean;
}

interface SocialRed {
  icon: JSX.Element;
  link: string;
  hoverBg: string;
}

interface Step {
  image: string;
  text: string;
}

interface Slide {
  image?: string;
  text: string;
  name: string;
  position: string;
}

interface ProductsDevice {
  device: DeviceFramesetProps["device"];
  size: number;
  right?: number;
  color: string;
  bottom: number;
  bgImage: string;
  tooltip: string;
}

export const howToUseItems: HowToUseItem[] = [
  {
    image: pagoFlashWallet,
    title: "PagoFlash Wallet",
    links: [{ text: "", link: routes.wallet }],
    description:
      "Cuenta transaccional de persona natural destinada a transferencias y pagos de todo tipo de compras.",
  },
  {
    image: pagoFlahBusiness,
    title: "PagoFlash Business",
    links: [{ text: "", link: routes.business }],
    description:
      "Cuenta transaccional de persona jurídica, permitiéndole realizar PagoNómina y otros servicios...",
  },
  {
    image: pagoFlashCommerce,
    title: "PagoFlash Commerce",
    links: [{ text: "", link: routes.commerce }],
    description:
      "Es un servicio de mecanismo de recepción de pagos que se adapta a tu e-commerce de manera ágil y sencilla.",
  },
];

export const doYouKnow: DoYouKnowDescription[][] = [
  [
    {
      text: "Productos enfocados en ti que",
    },
    {
      text: "te harán vender más",
      highlight: true,
    },
  ],
  [
    {
      text: "Gestionamos y hacemos seguimiento",
    },
    {
      text: " de tus pagos",
      highlight: true,
    },
  ],
  [
    {
      text: "Garantizamos la",
    },
    {
      text: " seguridad",
      highlight: true,
    },
    {
      text: " de tu dinero",
    },
  ],
  [
    {
      text: "No importa el tamaño de tu negocio",
    },
    {
      text: " la atención será personalizada",
      highlight: true,
    },
  ],
];

export const socialRed: SocialRed[] = [
  {
    icon: <BsTwitter />,
    link: "https://twitter.com/pagoflash",
    hoverBg: "#46d4fe",
  },
  {
    icon: <BsFacebook />,
    link: "https://www.facebook.com/PagoFlash",
    hoverBg: "#37589b",
  },
  {
    icon: <BsInstagram />,
    link: "https://www.instagram.com/pagoflashve/",
    hoverBg: "#a67658",
  },
  {
    icon: <BsLinkedin />,
    link: "https://www.linkedin.com/company/pagoflash-c-a",
    hoverBg: "#419cca",
  },
];

export const homeSteps: Step[] = [
  {
    text: "Descargue y realice el Llenado del formulario.",
    image: imgETFC1,
  },
  {
    text: "Envía tu cedula y RIF junto al formulario a nuestro e-mail: atencionalcliente@pagoflash.com",
    image: imgMailPhone,
  },
  {
    text: "Te contactara una ejecutiva para validar tus datos y entregar las credenciales de acceso.",
    image: imgETFC3,
  },
];

export const pagoClickSteps: Step[] = [
  {
    text: "Descargue y realice el Llenado del formulario.",
    image: imgETFC1,
  },
  {
    text: "Envía tu cedula y RIF junto al formulario a nuestro e-mail: atencionalcliente@pagoflash.com",
    image: imgMailPhone,
  },
  {
    text: "Te contactara una ejecutiva para validar tus datos y entregar las credenciales de acceso.",
    image: imgETFC3,
  },
];

export const slides: Slide[] = [
  {
    text: "Trabajar con PagoFlash ha sido muy satisfactorio, hemos realizado varias integraciones con su plataforma y lo que más nos ha gustado es su atención y apoyo en cada requerimiento, poseen un plataforma muy estable y moderna, altamente recomendados.",
    image: slideZuliatect,
    name: "José González",
    position: "CEO Zuliatec",
  },
  {
    text: "PagoFlash nos ha permitido brindar a nuestros clientes opciones de pago ajustadas a sus necesidades para nuestro principal producto: los cursos de capacitación online para el teletrabajo. Cuando hemos tenido alguna inquietud o duda nos comunicamos inmediatamente con Soporte y recibimos una respuesta o solución oportuna a nuestro requerimiento.",
    name: "Dessire Izaguirre",
    position: "Fundadora de Profesional Virtual",
  },
  {
    text: "Estamos complacidos en contar con PagoFlash, servicio de pago mediante el uso de tarjetas de crédito ideal para los personas naturales y jurídicas que quieren desarrollar sus ventas. Además cuenta con un gran equipo de atención que te ayudan en el proceso a resolver cualquier detalle técnico ¡Gracias a todo el equipo!",
    name: "José Moreno",
    position: "Distribuidora Sweet Cristal",
  },
  {
    text: "Para La casa del árbol Café, PagoFlash ha sido una oportunidad para solventar los inconvenientes de pagos que han surgido por la escasez de puntos de venta en el país, la plataforma se ha convertido en un aliado seguro y eficaz. Nuestros clientes siempre se ven interesados en conocer cómo funciona y al igual que nosotros se han sentido confiados al usarla, por esa razón siempre los recomendamos.",
    image: slideLaCasaDelArbol,
    name: "Andrea Sánchez",
    position: "Presidente La Casa del Árbol",
  },
  {
    text: "He logrado aumentar mis ventas gracias a PagoFlash, es súper rápida, eficaz y súper puntual con los pagos. Agradecidos enormemente por brindarnos esta facilidad.",
    name: "Laura Hurtado",
    position: "Atilios Burger",
  },
  {
    text: "Sin duda una solución práctica y sencilla para nosotros y nuestros clientes. De fácil implantación y que verdaderamente suma valor a nuestra experiencia en línea.",
    image: slideCarbone,
    name: "Ricardo Arispe",
    position: "Carbone Espresso Café",
  },
];

export const productDevices: ProductsDevice[] = [
  {
    device: "MacBook Pro",
    size: 1.8,
    color: "black",
    bottom: 0,
    bgImage: imgDeviceLaptop,
    tooltip: "PagoFlash Commerce",
  },
  {
    device: "iPad Mini",
    size: 3.5,
    right: -80,
    color: "black",
    bottom: 0,
    bgImage: imgDeviceTablet,
    tooltip: "PagoFlash Business",
  },
  {
    device: "iPhone X",
    size: 5.5,
    right: -120,
    color: "gold",
    bottom: -2,
    bgImage: imgDevicePhone,
    tooltip: "PagoFlash Wallet",
  },
];
